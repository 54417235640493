import { Injectable, inject } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs';
import { UserGroup } from '../shared/interfaces/app.interfaces';
import { AuthenticatedState } from './authenticated.state';
import { AuthenticatedStore } from './authenticated.store';

@Injectable({ providedIn: 'root' })
export class AuthenticatedQuery extends Query<AuthenticatedState> {
  protected override store: AuthenticatedStore;

  user$ = this.select('user');

  groups$ = this.select('user').pipe(map((user) => (user && user.groups ? user.groups : [])));

  isAdmin$ = this.select('user').pipe(
    map((user) => {
      if (!user) return false;
      return user.groups?.includes(UserGroup.ADMIN);
    }),
  );

  isEditor$ = this.select('user').pipe(
    map((user) => {
      if (!user) return false;
      return user.groups?.includes(UserGroup.EDITOR);
    }),
  );

  isAdminOrEditor$ = this.select('user').pipe(
    map((user) => {
      if (!user) return false;
      return user.groups?.includes(UserGroup.ADMIN) || user.groups?.includes(UserGroup.EDITOR);
    }),
  );

  constructor() {
    const store = inject(AuthenticatedStore);

    super(store);
    this.store = store;
  }
}
